
.mr1px {
    margin-right: 1px !important;
}
.mr5px {
    margin-right: 5px !important;
}
.ma30px {
    margin: 30px;
    max-width: 120px;
    max-height: 120px;
}
.row-details {
    padding-top: 1rem;
    padding-left: 1rem;
    border-bottom: 1px solid rgb(217, 218, 217);
}
.row-cell {
    padding-top: 1rem;
}
.min3em {
    min-width: 3em;
}
.paginator-child {
    background-color: #007ad9 !important;
    position: absolute;
    z-index: 3;
    width: 10em;
}
.paginator-child > label {
    background-color: #007ad9;
    color: #ffffff;
}
.paginator-child > .p-dropdown-trigger {
    background-color: #007ad9 !important;
    color: #ffffff !important;
}
.hv-fit-1btn {
    width:  calc(100% - 34px);
}
.hv-no-focus { 
    border-color: #a6a6a6 !important; 
    outline: none;  
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
  }
.hv-h2 {
    font-size: 1.5em;
    font-weight: bold;
}
.hv-btn-fixed {
    min-width: 8em;
}
.hv-upload{
    width: 7em;
    margin-top: 4em
}
/* image in circle */
div.circle-avatar{
    /* make it responsive */
    max-width: 100%;
    width:100%;
    height:auto;
    display:block;
    /* div height to be the same as width*/
    padding-top:100%;
    
    /* make it a circle */
    border-radius:50%;
    
    /* Centering on image`s center*/
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;
    
    /* it makes the clue thing, takes smaller dimension to fill div */
    background-size: cover;
    
    /* it is optional, for making this div centered in parent*/
    margin: 0 auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    }