body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
body a {
  cursor: pointer;
}
.App {
  height: 89vh;
}
.p-card-header {
  text-align: center;
}
.table-bordered {
  background-color: #c0c0c0;
}
.table-bordered td, th {
  background-color: #edf0f5;
  padding: 5px;
}
.hv-float-btn {
  float: right !important;
  bottom: 20em; 
}
input.hv-no-focus:focus { 
  border-color: none; 
  outline: none;  
  -webkit-box-shadow: none;
          box-shadow: none;
}
.hv-noshow {
  display: none !important;
}
.hv-sidebar-avatar {
  max-width: 3em;
  text-align: center;
  margin: auto;
  margin-bottom: 1em;
}
.hv-table-noborder div {
  border: none !important;
}
@media (min-width: 576px) {
  .hvleft {
    text-align: left;
  }
  .hvright {
    text-align: right;
  }
}
/* mobile-friendly hiding*/
 
/* .hidden-xs-up {
  display: none !important;
} */

@media (max-width: 200px) {
  .hidden-xs-up {
    display: unset;
  }
}

@media (max-width: 575px) {
  .hidden-xs-down {
    display: none !important;
  }
}

@media (min-width: 576px) {
  .hidden-xs-up {
    display: none !important;
  }
  .hidden-sm-up {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .hidden-sm-down {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .hidden-md-up {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .hidden-md-down {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .hidden-lg-up {
    display: none !important;
  }
}

@media (max-width: 1199px) {
  .hidden-lg-down {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-xl-up {
    display: none !important;
  }
}

.hidden-xl-down {
  display: none !important;
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}