@import url("https://fonts.googleapis.com/css?family=Fira+Sans:600|Heebo:400,500,700&display=swap");

html {
    overflow: hidden;
    height: 100%;
    width: 100%; 
    /*position: fixed;*/
}
body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    /* pointer-events: none;*/
    -webkit-overflow-scrolling: touch;
    /*position: relative;*/
}
.whitebkg {
    background: #ffffff !important;
}
#no-landscape {
    height: 100vh;
    width: 100vw;
    text-align: center;
    box-sizing: border-box; 
    z-index: 10;
    color: white;
    background-color: black;
    font-family: "Heebo", sans-serif;
    font-size: 1.2rem;   
}
#no-landscape div {
    margin-top: 33vh;
}
textarea, select, input, button {
    outline: none;
    box-shadow: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

video {
    background: #000000;
    /*object-fit: initial;*/
    object-fit: cover;
    width: 100%;
    height: 100%;
}

video::-webkit-media-controls-panel {
    background-image: none !important; 
}

#header,
a {
  color: white;
  text-decoration: none;
}

#blanket {
    background: #000000;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}
#entry {
    background: #000000;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: auto;
    text-align: center;
    padding: 2em;
    line-height: 3em;
    color: darkgrey !important; 
}
#entry h1, h2, h3 {
    color: #ffffff !important;
}
#entry-controls {
    margin-top: 2em;
    margin-bottom: 2em;
}

#local-wrap {
    pointer-events: auto;
}

#local-wrap p {
    z-index: 101;
    position: absolute;
    color: white;
    font-family: "Heebo", sans-serif;
    white-space: nowrap;
    font-weight: bold;
    background: #000000;
    border-radius: 5px;
    font-size: 0.7em;
}

.viwrap {
    border-radius: 10px;
    /*background: #000000;*/
    text-align: center;
}
.pointer {
    cursor:pointer;
}
.half {
    height: 50vh;
}
.halfw {
    width: 50%;
}
.fullw {
    width: 100%;
}
#local-video {
  width: 100%;
  height: auto;
  border-radius: 10px;
  /*-webkit-transform: scaleX(-1);
  transform: scaleX(-1);*/
  background: #000000; 
}

#local-video-text {
    text-align: center !important;
    box-sizing: border-box;
    margin: 0;
    position: absolute;
    z-index: 1;
    color: white;
    font-family: "Heebo", sans-serif;
    font-size: 1.2rem;
    /*font-weight: bold;*/ 
    padding: 10px;
    left: 0;
    top: 1em;
    pointer-events: auto;
}
#remote-video-text {
    text-align: center !important;
    box-sizing: border-box;
    margin: 0;
    margin-top: 60vh;
    position: absolute;
    z-index: 1;
    color: white;
    font-family: "Heebo", sans-serif;
    font-size: 1.2rem;
    /*font-weight: bold;*/
    text-align: left;
    border-radius: 0 0 10px 10px;
    padding: 10px;
    top: 1em;
    width: 95%;
    pointer-events: auto;
}

#rv-extra {
    position: absolute;
    margin-top: 7em;
    font-size: smaller;
    width: 95vw;
}
#remote-video {
  padding: 0;
  margin: 0; 
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  border-radius: 10px;
  background-image: url(./images/waiting.svg);
  background-size: 200px auto;
  background-repeat: no-repeat;
  background-position: center center;
}

#toggle-self {
    right: 0;
    z-index: 3;
    position: absolute;
    padding-left: 63px;
    height:70px;
}
.toggle-hide {
    background: url(./images/close.png) no-repeat center left;
    float: right;
    cursor: pointer;
}

.toggle-show {
    background: url(./images/owner.png) no-repeat center left;
    float: right;
    cursor: pointer;
}
button:hover {
  color: white;
}

.entryHints {
    min-height: 4em;
    font-size: 0.8em;
    text-align: left;
    line-height: 2em;
    width: 15em;
    margin: auto;
}
.entryBtn {
    width: 7em;
    pointer-events: auto;
}
.videoBtn {
    width: 5em;
    pointer-events: auto;
}
.btnHintRow { 
    font-size: 0.8rem;
    margin-top: 0.5em;
    left: 60px;
    white-space: nowrap;
    top: 0px;
    font-weight: bold;
    background: #000000;
    padding: 10px;
}
.btnHint {
    color: white;
    font-family: "Heebo", sans-serif;
    font-size: 0.8rem; 
    left: 60px;
    white-space: nowrap;
    top: 0px;
    font-weight: bold;
    background: #000000;
    padding-bottom: 10px;
}

#chat-area { 
  height: 100%;
  right: 0;
  width: auto;
  padding: 0;
}

.compose { 
  width:14vw;
  height: 60px;
  font-family: "Heebo", sans-serif;
  position: fixed;
  bottom: 0; 
  z-index: 100;
  border: 1px solid #1c1d22;
  border-radius: 5px; 
  margin-bottom: 2px;
  box-sizing: border-box;
  background-color: whitesmoke;
  overflow: hidden; 
  -ms-overflow-style: none;  
  pointer-events: auto;
  /*max-height: 5em;*/
}

.compose input {
  font-family: inherit;
  font-size: 0.8em;
  border: none;
  width: 70%;
  height: calc(100% - 5px);
  resize: none;
  outline: none;
  background-color: inherit;
  color: #1c1d22;
  float:left;
  position:relative;
  pointer-events: auto;
}
.compose button {
    float: left; 
    position: fixed;
    /*top: 0.5em;*/
    bottom: 1em;
    right: 0.6em;
    height: 2.5em;
}
.active-compose {
    float: right;
    position: fixed;
    top: 2em;
    /*bottom: 1em;*/
    right: 1em;
    height: 2.5em;
}
.compose input::placeholder {
  color: white;
}
.chat-mobile {
    height: calc(100% - 9em) !important;
}
#text-area {
    /*border: solid 1px #c0c0c0;*/
    border-radius: 10px 10px 10px 10px;
    width: 100%;
    height: 100%;
    padding: 0.5em;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: "Heebo", sans-serif;
    font-size: 0.8rem;
    background-color: aliceblue;
    -webkit-overflow-scrolling: none;
}
#text-input {
    background-color: inherit;
    border: none !important;
    color: #1c1d22;
    font-size: initial;
    line-height: normal;
    /*height: 96%;*/
    min-height: 60px;
    width: calc(100% - 60px);
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0.5em;
    padding-left: 0.5em;
    resize: none;
    /*z-index: 5;*/
}
#btn-input {
    position: absolute;
    float: right;
    margin: 0.5em;
    right: 0.2em;
}
#chat-zone {
    position: relative;
    padding-top: 20px;
    box-sizing: border-box;
    /*height: 91%;*/
    height: calc(90% - 3em);
    right: 0;
    border: solid 1px #c0c0c0;
    border-radius: 10px 10px 10px 10px;
    width: 19.5vw;
    overflow: hidden;
    font-family: "Heebo", sans-serif;
    font-size: 0.9rem;
    background-color: seashell;
    background: url(./images/double-bubble-outline.png);
    -webkit-overflow-scrolling: none;
}

#chat-zone::-webkit-scrollbar {
  width: 0 !important;
}

#chat-zone .chat-messages .message-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0 2px 2px;
  margin-bottom: 2px;
}

.chat-messages {
    pointer-events: auto;
    position: absolute;
    overflow-x: hidden;
    padding-bottom: 1em;
    padding-left: 0.6em;
    padding-right: 0.6em;
    overflow-y: auto;
    width: 14vw;
    margin-bottom: 0;
    max-height: 98%;
    bottom: 0;
    z-index: 3;
}

#chat-zone .chat-messages .message-item.owner {
    /*padding-left: 2vw;*/
    width: 100%;
    /*right: 1vw;*/
    display: inline-grid; 
}

#chat-zone .message-item.customer .message-bloc {
    background-color: #b8f2e6; 
    /*#7cadd8;*/
    color: #1c1d22;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    max-width: 100%;
    border-radius: 5px 5px 5px 5px;
}

#chat-zone .message-item.owner .message-bloc {
    background-color: #cae9ff;
    /*#aed9e0;*/
    /*#3399F3;*/
    color: #1c1d22;
    border-radius: 3px 3px 3px 3px;
}

#chat-zone .chat-messages .message-item .message-bloc {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  max-width: 10vw;
}

/* screen-wide chat*/
#chat-zone .message-item.customer .wide-bloc {
    background-color: #b8f2e6;
    color: #1c1d22;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    max-width: 100%;
    border-radius: 3px 3px 3px 3px;
}

#chat-zone .message-item.owner .wide-bloc {
    background-color: #cae9ff;
    color: #1c1d22;
    border-radius: 3px 3px 3px 3px;
}

#chat-zone .chat-messages .message-item.owner .wide-bloc {
    margin-left: 20vw;
}

#chat-zone .chat-messages .message-item .wide-bloc {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px;
    max-width: 70vw;
}

.message {
  word-break: break-word;
  text-align:left;
}

.message a {
  text-decoration: underline;
}

input:focus::placeholder {
    color: transparent;
}
.chat-scroll {
    animation-duration: 1s;
    animation-fill-mode: both;
}

.chat-fadein {
    animation-name: fadeInBottom;
}

@keyframes chat-fadein {
    from {
        opacity: 0;
        transform: translateY(200%);
    }

    to {
        opacity: 1;
    }
}
/*
.cssanimation {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.fadeInBottom {
  animation-name: fadeInBottom;
}

@keyframes fadeInBottom {
  from {
    opacity: 0;
    transform: translateY(200%);
  }
  to {
    opacity: 1;
  }
}
*/

.flex-sbs {
    float: left;
    padding: 3px;
    text-align: center;
    border: 2px solid #ffffff;
    margin-right: 0.5vw;
}

.side-by-side {
    float: left;
    padding: 3px;
    width: 47vw;
    height: 60vh;
    border-radius: 10px;
    background: #000000;
    text-align: center;
    margin-right:0.5vw;
    border: 2px solid #ffffff;
}
.container {
    height: 100%;
}
.row {
    height: 100%;
}
.regular{
    font-size: 0.8em !important;
    font-weight: normal;
}
.regular h4 {
    font-size: 1.2em !important;
    font-weight: bold;
}
#fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh; 
    background-color: #000000;
    z-index: 999;
}
#fullscreen.show {
    visibility: visible;
    opacity: 1;
}
#right-rail {
    margin-top: 0 !important;
}
.hnh {
    background-color: #000000;
    float: left;
    width: 50%;
    height: auto;
}

#area {
    /*margin: 0.5em;
    margin-right: 1em;*/
    /*width: 95vw;*/
    height: 100%;
    overflow: hidden;
    /*border-radius: 10px;*/
    background: #000000;
    text-align: center;
}
.pip {
    width: 20vw;
    min-width: 100px;
    position: absolute;
    margin-right: 1em;
    border: 2px solid antiquewhite;
    z-index: 1;
    right: 0;
    top: 0.5em;
    align-items: center;
    justify-content: center;
    border-radius: 5px 5px 5px 5px;
}

.pip-noborder {
    width: 20vw;
    min-width: 100px;
    position: absolute;
    margin-right: 1em;
    z-index: 1;
    right: 0;
    top: 0.5em;
    align-items: center;
    justify-content: center;
}
.noshow {
    display:none !important;
}

.btn-toolbar {
    position: absolute;
    height: auto;
    width: 100%;
    left: 0;
    bottom: 0.5em;
    margin-bottom: 0.5em;
    z-index: 1;
    justify-content: center !important;
    pointer-events: auto;
}
.black95 {
    width: 100%;
    background-color: #000000;
    margin: 0;
}
.ctable {
    display: table;
    width: 100%;
}

.ctable .ctcol {
    display: table-cell;
    vertical-align: middle;
    width: auto;
    padding: 10px;
}

.s5 {
    position: absolute;
    right: 0;
}
.ui-dialog-titlebar {
    font-size: 0.8em !important;
}
.anymsg {
    font-size: 0.7em;
}
.prompt {
    width:15em;
    margin-bottom:0.5em;
}
.modal-body {
    font-size: 1.1em;
    line-height: 1.2em;
}
#header {
    padding-left: 1em;
    width: 100%;
    background: rgb(240,240,240);
    font-family: "trebuchet ms",arial,sans-serif;
}

.header-top {
    width: 100%;
    height: 62px;
    background: rgb(240,240,240) url('https://secure.claimmanager.ca/images/public/claim-manager-logo.png');
    background-repeat: no-repeat;
    overflow: visible !important /*Firefox*/;
    overflow: hidden /*IE6*/;
}

.wrapper {
    margin:1em;
    border: 1px solid #c0c0c0;
}

#errorBody ol {
    padding-left: 1.5em;
}
.entry-label {
    color: #ffffff;
}
.layoutrow:before, .layoutrow:after {
    display: table;
    content: " ";
    margin: 0.5em;
}
.info-table {
    font-size: 1rem;
    /* color: #ffffff; */
}
.info-table table, td, th {
    /* color: #ffffff; */
    text-align:left;
}
@media (max-width: 992px) {
    .modal-body {
        font-size: 0.6em;
        line-height: 1.4em;
    }
    .modal-header h5 {
        font-size: 0.9em;
    }
    #chat-zone { 
        font-size: 1rem; 
    }
    #local-video-text {
        font-size: 1rem;
    }
    #remote-video-text {
        font-size: 1rem;
    }
    #entry {
        font-size: 1rem;
        padding: 1rem;
        line-height: 3em;
        overflow: auto;
        pointer-events: auto;
    }
    #entry-controls {
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .entryHints {
        font-size: 1.1rem;
    }
    .entryHints ul {
        list-style-type:none;
    }

}
@media (max-width: 480px) {
    .videoBtn {
        width: auto;
    }
    .modal-body {
        font-size: 0.6em;
        line-height: 1.4em;
    }
}