.customer-badge {
    border-radius: 2px;
    padding: .25em .5em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.status-active {
        background-color: #C8E6C9;
        color: #256029;
    }

.status-expired {
        background-color: #FFCDD2;
        color: #C63737;
    }

.status-negotiation {
        background-color: #FEEDAF;
        color: #8A5340;
    }

.status-new {
        background-color: #B3E5FC;
        color: #23547B;
    }

.status-renewal {
        background-color: #ECCFFF;
        color: #694382;
    }

.status-proposal {
        background-color: #FFD8B2;
        color: #805B36;
    }
.inside-prev {
    float: left;
    position: absolute;
    margin-left: -1.4em;
    margin-top: 0.4em;
} 