/**
 * copied from ASP.NET Core Starter Kit (https://dotnetreact.com)
 */

.content {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.ribbon {
  width: 100%;
  height: 40vh;
  background-color: #3f51b5;
  background-image: linear-gradient(-225deg, #3db0ef, #5e5bb7);
  flex-shrink: 0;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
}

.tagline,
.summary {
  color: #fff;
}

.tagline {
  font-size: 3em;
  margin-top: 6vh;
}

.summary {
  font-size: 2em;
}
