@font-face {
    font-family: 'PrimeIcons';
    src: url('fonts/primeicons.eot');
    src: url('fonts/primeicons.eot?#iefix') format('embedded-opentype'), url('fonts/primeicons.ttf') format('truetype'), url('fonts/primeicons.woff') format('woff'), url('fonts/primeicons.svg?#primeicons') format('svg');
    font-weight: normal;
    font-style: normal;
}

.pi {
    font-family: 'primeicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pi-fw {
    width: 1.28571429em;
    text-align: center;
}

.pi-spin {
    -webkit-animation: fa-spin 2s infinite linear;
    animation: fa-spin 2s infinite linear;
}

@-webkit-keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes fa-spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.pi-cloud-download:before {
    content: "\e943";
}

.pi-cloud-upload:before {
    content: "\e944";
}

.pi-cloud:before {
    content: "\e945";
}

.pi-pencil:before {
    content: "\e942";
}

.pi-users:before {
    content: "\e941";
}

.pi-clock:before {
    content: "\e940";
}

.pi-user-minus:before {
    content: "\e93e";
}

.pi-user-plus:before {
    content: "\e93f";
}

.pi-trash:before {
    content: "\e93d";
}

.pi-external-link:before {
    content: "\e93c";
}

.pi-window-maximize:before {
    content: "\e93b";
}

.pi-window-minimize:before {
    content: "\e93a";
}

.pi-refresh:before {
    content: "\e938";
}
  
.pi-user:before {
    content: "\e939";
}

.pi-exclamation-triangle:before {
    content: "\e922";
}

.pi-calendar:before {
    content: "\e927";
}

.pi-chevron-circle-left:before {
    content: "\e928";
}

.pi-chevron-circle-down:before {
    content: "\e929";
}

.pi-chevron-circle-right:before {
    content: "\e92a";
}

.pi-chevron-circle-up:before {
    content: "\e92b";
}

.pi-angle-double-down:before {
    content: "\e92c";
}

.pi-angle-double-left:before {
    content: "\e92d";
}

.pi-angle-double-right:before {
    content: "\e92e";
}

.pi-angle-double-up:before {
    content: "\e92f";
}

.pi-angle-down:before {
    content: "\e930";
}

.pi-angle-left:before {
    content: "\e931";
}

.pi-angle-right:before {
    content: "\e932";
}

.pi-angle-up:before {
    content: "\e933";
}

.pi-upload:before {
    content: "\e934";
}

.pi-ban:before {
    content: "\e935";
}

.pi-star-o:before {
    content: "\e936";
}

.pi-star:before {
    content: "\e937";
}

.pi-chevron-left:before {
    content: "\e900";
}

.pi-chevron-right:before {
    content: "\e901";
}

.pi-chevron-down:before {
    content: "\e902";
}

.pi-chevron-up:before {
    content: "\e903";
}

.pi-caret-left:before {
    content: "\e904";
}

.pi-caret-right:before {
    content: "\e905";
}

.pi-caret-down:before {
    content: "\e906";
}

.pi-caret-up:before {
    content: "\e907";
}

.pi-search:before {
    content: "\e908";
}

.pi-check:before {
    content: "\e909";
}

.pi-check-circle:before {
    content: "\e90a";
}

.pi-times:before {
    content: "\e90b";
}

.pi-times-circle:before {
    content: "\e90c";
}

.pi-plus:before {
    content: "\e90d";
}

.pi-plus-circle:before {
    content: "\e90e";
}

.pi-minus:before {
    content: "\e90f";
}

.pi-minus-circle:before {
    content: "\e910";
}

.pi-circle-on:before {
    content: "\e911";
}

.pi-circle-off:before {
    content: "\e912";
}

.pi-sort-down:before {
    content: "\e913";
}

.pi-sort-up:before {
    content: "\e914";
}

.pi-sort:before {
    content: "\e915";
}

.pi-step-backward:before {
    content: "\e916";
}

.pi-step-forward:before {
    content: "\e917";
}

.pi-th-large:before {
    content: "\e918";
}

.pi-arrow-down:before {
    content: "\e919";
}

.pi-arrow-left:before {
    content: "\e91a";
}

.pi-arrow-right:before {
    content: "\e91b";
}

.pi-arrow-up:before {
    content: "\e91c";
}

.pi-bars:before {
    content: "\e91d";
}

.pi-arrow-circle-down:before {
    content: "\e91e";
}

.pi-arrow-circle-left:before {
    content: "\e91f";
}

.pi-arrow-circle-right:before {
    content: "\e920";
}

.pi-arrow-circle-up:before {
    content: "\e921";
}

.pi-info:before {
    content: "\e923";
}

.pi-info-circle:before {
    content: "\e924";
}

.pi-home:before {
    content: "\e925";
}

.pi-spinner:before {
    content: "\e926";
}